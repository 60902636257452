
  import { mapActions, mapGetters } from 'vuex'
  import { modalController, IonSlides, IonSlide, IonSkeletonText } from '@ionic/vue'
  import { arrowBack, caretForwardOutline } from 'ionicons/icons'
  import wAxios from '@/plugins/w/axios'
  import GalleryModal from '@/plugins/app/pin-details/gallery-modal.vue'
  import ZSummary from '@/plugins/app/_components/z-summary.vue'

  export default{
    components: {
      IonSlides, IonSlide, IonSkeletonText, ZSummary
    },

    watch: {
      $route (){
        console.log('1. ', this.$route)
        if (this.$route.params.id && this.$route.fullPath.includes('pin-detail'))
        {
          console.log('2. ', this.$route)
          this._fetchPin()
        }
      }
    },

    mounted() {
      this._fetchPin()
      this._handleSlidesRendering()
    },

    data() {
      return {
        pin: {},
        breakLength: 13,
        isLoading: false,
        slidesRendered: false,
        arrowBack,
        caretForwardOutline,
      }
    },

    computed: {
      ...mapGetters('wAuth', ['isLoggedIn']),

      images() {
        if (!this.pin.images)
          return []
        return Object.values(this.pin.images).map((image: any) => image.path)
      },

      initials() {
        return this.pin.title.substring(0, 2)
      }
    },

    methods: {
      ...mapActions('collection', ['removePin', 'addPin', 'fetchByPage']),
      ...mapActions('countries', ['changeNeedRefresh']),

      addOrRemovePinFromCollection(action) {
        if (!this.isLoggedIn) {
          return this.$router.push({ path: '/app/login' })
        }

        // add or remove
        this[action](this.pin.id)
        this.$store.commit('catalogue/inCollectionChanged', {pinId: this.pin.id, value: action == 'addPin'})
        this.$store.commit('countries/inCollectionChanged', {pinId: this.pin.id, value: action == 'addPin'})
        this.pin.in_collection = !this.pin.in_collection
      },
      pinAction(pin) {
        this.addOrRemovePinFromCollection(pin.in_collection ? 'removePin' : 'addPin')
        this.fetchByPage(1)
      },

      async openCountry(countryId) {
        this.changeNeedRefresh(true)
        this.$router.push(`/app/country-detail/${countryId}`)
      },

      async openGalleryModal(selectIndex) {
        const modal = await modalController.create({
          component: GalleryModal,
          componentProps: { images: this.images, selectIndex },
          cssClass: 'modal-all'
        })
        return modal.present()
      },

      async _fetchPin() {
        try {
          this.isLoading = true
          this.pin = await wAxios.get_auth(`v1/pins/${this.$route.params.id}`)
          this.isLoading = false
        } catch (err) {
          console.error(err)
        }
      },

      // WORKAROUND for slides rendering. Whole screen is not rendered without this
      _handleSlidesRendering() {
        setTimeout(() => this.slidesRendered = true, 500)
      },

      _linkify(inputText) {
        //URLs starting with http://, https://, or ftp://
        const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
        return inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')
      },
    }
  }
