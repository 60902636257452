<template>
  <ion-page>
    <ion-content class="ion-no-padding">
      <ion-icon @click="closeModal" :icon="closeOutline"></ion-icon>

      <v-zoomer-gallery
          style="width: 100vw; height: 100vh;"
          :list="images"
          v-model="index"
          pivot="image-center"
      ></v-zoomer-gallery>
    </ion-content>
  </ion-page>
</template>

<script>
  import { defineComponent } from 'vue'
  import { closeOutline } from 'ionicons/icons'
  import { modalController } from '@ionic/vue'

  export default defineComponent({
    props: {
      images: {type: Array, required: true},
      selectIndex: {type: Number, default: 1}
    },
    data() {
      return {
        closeOutline,
        index: 1
      }
    },
    mounted() {
      this.index = this.selectIndex
    },
    methods: {
      closeModal() {
        modalController.dismiss()
      },
    }
  })
</script>

<style lang="sass" scoped>
ion-icon
  color: #a7a7a7
  position: absolute
  top: 35px
  right: 20px
  font-size: 30px
  z-index: 10000
ion-content
  margin-top: env(safe-area-inset-top)
  --overflow: hidden
  --background: rgb(51, 51, 51)
</style>
